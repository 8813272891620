<script setup>
import { computed } from 'vue'
import FocusBar from '../layout/header/FocusBar.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'

const { refId, type, storeName, routeId } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const { client_company_id, company_name, user_fname, user_lname } =
  EntityComputedFields.useEntityComputedFields({
    refId: refId,
    store: storeName,
    type: type
  })

const name = computed(() =>
  client_company_id.value && company_name.value
    ? company_name.value
    : `${user_fname.value || ''} ${user_lname.value || ''}`
)
</script>

<template>
  <FocusBar>
    <template #middle>
      <div class="flex justify-center items-center w-full gap-2">
        <PersonAvatar :id="routeId" type="client" :name="name" already-editing />
        <div class="flex justify-center items-center w-fit gap-2">
          <StringField
            v-if="client_company_id"
            v-model="company_name"
            placeholder="No company name"
          />
          <template v-else>
            <StringField v-model="user_fname" placeholder="No first name" />
            <StringField v-model="user_lname" placeholder="No last name" />
          </template>
        </div>
      </div>
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
